<template>
  <NuxtImg
    alt="AwesomeTechStack logo white"
    src="/img/icon_white.png"
    :height="height"
    :width="width"
    :style="{
      width: width,
      height: height,
      'max-width': width,
      'max-height': height,
    }"
    quality="70"
  />
</template>

<script setup>
const width = ref(46)
const height = ref(46)

const props = defineProps({
  size: {
    type: [Number, String],
    default: 46,
  },
})

width.value = props.size
height.value = Math.round(props.size / 1.45)
</script>