<template>
  <div>
    <AppHero :title="page?.title" :description="page?.description"></AppHero>
    <v-divider></v-divider>

    <AppContainer>
      <SectionsIndexWebsiteAnalyzer></SectionsIndexWebsiteAnalyzer>
      <SectionsIndexLinks class="my-8"></SectionsIndexLinks>
      <SectionsIndexWebsiteInsights
        :items="recentlyAnalyzedWebsites || []"
      ></SectionsIndexWebsiteInsights>
      <AppDivider class="d-none d-sm-block" />
      <SectionsIndexTechnologyInsights
        :lastUpdatedAt="lastUpdatedAt"
        :items="topScoreByDays || []"
        :technologiesCount="stats?.technologies"
        :categoriesCount="stats?.categories"
      ></SectionsIndexTechnologyInsights>
      <AppDivider class="d-none d-sm-block" />
      <SectionsIndexHowItWorks></SectionsIndexHowItWorks>
      <AppBlockQuote class="text-sm-center"></AppBlockQuote>
      <AppDivider class="d-none d-sm-block" />
      <div class="py-4 py-sm-8">
        <PricingCards :standalone="false"></PricingCards>
      </div>
    </AppContainer>
  </div>
</template>


<script setup>
const route = useRoute()

const { data: page } = await useAsyncData('pageData', () =>
  queryContent(route.path).only(['title', 'description']).findOne()
)

const { data: recentlyAnalyzedWebsites } = await useFetch(
  `/api/v1/websites/recently/index?limit=4`
)
const { data: lastUpdatedAt } = await useFetch('/api/v1/techs/last-updated-at')
const { data: topScoreByDays } = await useFetch(
  '/api/v1/techs/top-score-by-days?limit=4&limitDays=28'
)
const { data: stats } = await useFetch(`/api/v1/technologies/stats`)

const title = page.value.head?.title || page.value.title
const description = page.value.head?.description || page.value.description

useSeoMeta({
  title: title,
  ogTitle: title,
  description: description,
  ogDescription: description,
})
</script>
