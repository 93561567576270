<template>
  <v-container class="sections-index-links">
    <v-row justify-lg="space-between" justify="center">
      <v-col
        v-for="{ text, icon, to } in items"
        :key="text + icon"
        class="text-center"
        cols="6"
        sm="auto"
      >
        <app-link-btn
          :text="$t(`SectionsIndexLinks.${text}`)"
          :icon="icon"
          :to="to"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import {
  mdiWebClock,
  mdiFormatListNumbered,
  mdiTrendingUp,
  mdiChartDonut,
  mdiCreation,
} from '@mdi/js'

const items = [
  {
    text: 'recentlyanalyzedwebsites',
    icon: mdiWebClock,
    to: {
      name: 'insights-websites-recently-analyzed',
    },
  },
  {
    text: 'top10techstackwebsites',
    icon: mdiFormatListNumbered,
    to: {
      name: 'insights-websites-top-tech-stack',
    },
  },
  {
    text: 'top10technologies',
    icon: mdiTrendingUp,
    to: {
      name: 'insights-technologies-awesome',
    },
  },
  {
    text: 'top10mostusedtechnologies',
    icon: mdiChartDonut,
    to: {
      name: 'insights-technologies-most-used',
    },
  },
  {
    text: 'awesomewebsites',
    icon: mdiCreation,
    to: {
      name: 'insights-websites-awesome',
    },
  },
]
</script>