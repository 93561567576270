<template>
  <div>
    <svgo-landing
      class="svgo-landing d-lg-block d-none"
      style="width: 100%"
    ></svgo-landing>
    <div class="py-0 py-sm-10">
      <v-container class="text-center" style="max-width: 700px">
        <div class="d-flex flex-column align-center">
          <div>
            <h1 class="mb-4 gradient">
              {{ title }}
            </h1>
            <p class="text-medium-emphasis">
              {{ description }}
            </p>
          </div>
          <div class="mt-8">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="auto">
                <v-btn
                  class="d-flex flex-grow-1 flex-md-shrink-1"
                  size="x-large"
                  :to="{ name: 'products-website-analyzer' }"
                >
                  <app-icon-white
                    v-if="colorMode.value !== 'dark'"
                    class="mr-4"
                    size="32"
                  ></app-icon-white>
                  <app-icon-black
                    v-if="colorMode.value === 'dark'"
                    class="mr-4"
                    size="32"
                  ></app-icon-black>
                  Website analyzer
                </v-btn>
              </v-col>
              <v-col cols="12" sm="auto">
                <v-btn
                  block
                  class="border"
                  size="x-large"
                  variant="tonal"
                  :to="{ name: 'why' }"
                >
                  AwesomeTechStack?
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script setup>
import { mdiHeadQuestionOutline } from '@mdi/js'
import SvgoLanding from '@/assets/icons/landing.svg?component'

const colorMode = useColorMode()

defineProps({
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
})
</script>
